.portfolio__container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1rem;
    width: 500px;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 30%;
}

.portfolio__item h4 {
    /* font-weight: bold; */
    margin: 2rem 0;
}

.portfolio__item h3 {
    margin: 2rem 0;
}

.portfolio__item p {
    margin: 0 0 1.2rem 0;
    font-size: 0.9rem;
    text-align: justify;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.portfolio__item-content {
    height: 50%;
}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
